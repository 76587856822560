import React, { useState } from "react";
//session
import useSessionStore from "../store/useSessionStore";
//lib
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
//componets
import SocialLoginBtn from "../../common/components/SocialLoginBtn";
import {
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CForm,
} from "@coreui/react";
//api
import {
  useLogout,
  useKakaoWithdraw,
  useGetUser,
  useKakaoTalk,
} from "../api/AfterLoginApi";

//server
import {
  useNaverUnlink,
  useGoogleUnlink,
  useDeviceList,
} from "../api/AfterLoginApi";
import { useMessage, useEmail } from "../api/MessageApi";
import { useUploadImg } from "../api/AdminApi";

//utils
import { validateImageFile } from "../../common/Utils";

const AfterLogin = () => {
  const { userInfo } = useSessionStore();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm();

  const { trigger: expireTrigger } = useLogout();
  const { trigger: getUserTrigger } = useGetUser();
  const { trigger: withdrawTrigger } = useKakaoWithdraw();

  const { trigger: naverCutlink } = useNaverUnlink();
  const { trigger: googleCutlink } = useGoogleUnlink();

  const { trigger: sendMessage } = useMessage();
  const { trigger: sendEmail } = useEmail();

  const { trigger: sendTalkMsg } = useKakaoTalk();
  const { trigger: uploadFileImage } = useUploadImg();

  //get
  const { data: deviceList } = useDeviceList(userInfo?.memberId);

  const kakaoWithdraw = async () => {
    const response = await withdrawTrigger(userInfo);
    console.log("연동 취소 후 카카오 유저 id = ", response);
    sessionStorage.clear();
    navigate("/");
  };

  const kakaoLogout = async () => {
    const url = `https://kauth.kakao.com/oauth/logout?client_id=${process.env.REACT_APP_KAKAO_KEY}&logout_redirect_uri=${process.env.REACT_APP_KAKAO_LOGOUT_REDIRECT}`;
    window.location.href = url;
  };

  const expireToken = async () => {
    await expireTrigger(userInfo);
    sessionStorage.clear();
    navigate("/");
  };

  const callApi = async () => {
    const response = await getUserTrigger();
    setData(response.res_data);
  };

  const naverUnlink = async () => {
    const response = await naverCutlink(userInfo);
    sessionStorage.clear();
    console.log(response);
    navigate("/");
  };

  const googleUnlink = async () => {
    const response = await googleCutlink(userInfo);
    sessionStorage.clear();
    console.log(response);
    navigate("/");
  };

  const sendAlarm = async () => {
    try {
      const response = await sendMessage(userInfo);
      console.log(response);
    } catch ({ response: { data } }) {
      console.log(data);
      window.alert(data?.res_msg);
    }
  };

  const email = async () => {
    try {
      const response = await sendEmail(userInfo);
      console.log("check response = >", response);
    } catch (e) {
      console.log(e);
    }
  };

  const kakaosendTalk = async () => {
    try {
      const response = await sendTalkMsg(userInfo);
      console.log(response);
    } catch ({ response: { data } }) {
      window.alert(data.res_msg);
    }
  };

  // ====================================================
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("imageFile", data.imageFile[0]);
    console.log("formData 데이터 체크", formData);

    console.log();
    if (data.imageFile.length === 0) {
      window.alert("이미지를 선택해주세요");
      return;
    }

    if (validateImageFile(data)) {
      try {
        const response = await uploadFileImage(formData);
        console.log("이미지 업로드", response);
        if (response.res_data.status === "OK") {
          window.alert("업로드 되었습니다!");
        }
      } catch ({ response: { data } }) {
        console.log(data);
        window.alert(data.res_msg);
      }
    }
  };
  // ====================================================

  console.log("check devices", deviceList);

  return (
    <div>
      {deviceList?.res_data?.map((item) => (
        <div className="d-flex mx-5" key={item?.id}>
          {item.os_name + " " + item.device_type + " " + item.browser_name}
        </div>
      ))}
      <div className="d-flex justify-content-around mx-5">
        <CButton onClick={() => navigate("/stream")}>비디오 페이지</CButton>
        <CButton onClick={() => navigate("/image")}>이미지 페이지</CButton>
        <CButton onClick={() => navigate("/variant")}>
          이미지 폴더 페이지
        </CButton>
        <CButton onClick={() => navigate("/toss")}>토스페이</CButton>
        <CButton onClick={() => navigate("/toss/cancel")}>
          토스페이 환불
        </CButton>
        <CButton onClick={() => navigate("/member/logs")}>
          회원 로그인 로그 데이터
        </CButton>
        <CButton onClick={() => navigate("/audio")}>오디오 리스트</CButton>
      </div>
      <div className="d-flex justify-content-around">
        <div className="d-flex flex-column mt-5">
          <h1 className="d-flex justify-content-center">{`hello ~ ${userInfo.username}`}</h1>

          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="회원탈퇴 (서비스 로그인 연동해제)"
              color="primary"
              action={kakaoWithdraw}
            />
          </div>

          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="로그아웃"
              color="primary"
              action={kakaoLogout}
            />
          </div>
          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="로그아웃 (토큰 만료 API)"
              color="primary"
              action={expireToken}
            />
          </div>

          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="요청 api"
              color="primary"
              action={callApi}
            />
          </div>

          {data?.map((item) => (
            <div
              className="d-flex justify-content-center"
              key={item?.member_id}
            >
              {item?.email}
            </div>
          ))}
        </div>
        <div className="d-flex flex-column mt-5">
          <h1>naver 버튼</h1>
          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="네이버 (서비스 로그인 연동해제)"
              color="primary"
              action={naverUnlink}
            />
          </div>

          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="공용 로그아웃 (세션 스토리지 삭제)"
              color="primary"
              action={expireToken}
            />
          </div>
        </div>

        <div className="d-flex flex-column mt-5">
          <h1>google 버튼</h1>
          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="구글 (서비스 로그인 연동해제)"
              color="primary"
              action={googleUnlink}
            />
          </div>

          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="공용 로그아웃 (세션 스토리지 삭제)"
              color="primary"
              action={expireToken}
            />
          </div>
        </div>

        <div className="d-flex flex-column mt-5">
          <h1>알람톡 버튼</h1>
          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="알람톡 보내기"
              color="primary"
              action={sendAlarm}
            />
          </div>

          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="이메일 보내기"
              color="primary"
              action={email}
            />
          </div>

          <div className="d-flex justify-content-center mt-5">
            <SocialLoginBtn
              btnName="친구톡 보내기"
              color="primary"
              action={kakaosendTalk}
            />
          </div>

          <div className="d-flex justify-content-center mt-5">
            <CForm onSubmit={handleSubmit(onSubmit)}>
              <CFormInput
                {...register("imageFile")}
                type="file"
                id="imageFile"
                label="이미지 파일 선택"
                accept=".png, .jpg"
              />
              <CButton className="mt-2" type="submit">
                이미지 업로드
              </CButton>

              <CButton
                className="mt-2 mx-2"
                onClick={() => navigate("/talk/image")}
              >
                업로드 이미지 조회
              </CButton>
            </CForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfterLogin;
