import React, { useState, useEffect } from "react";

//ui
import {
  CForm,
  CButton,
  CFormInput,
  CFormCheck,
  CFormSelect,
} from "@coreui/react";

//lib
import { useForm } from "react-hook-form";
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/themes/monikai.css";

//server

import {
  useVariantList,
  useVariantCreate,
  useVariantUpdate,
  useVariantDelete,
} from "./api/CloudVariantApi";

const CloudVariant = () => {
  const [variant, setVariant] = useState({});
  const [variantsList, setVariantList] = useState([]);
  const [mode, setMode] = useState("select");

  const { data: variants, mutate, isLoading: listLoading } = useVariantList();

  const { trigger: variantCreate } = useVariantCreate();
  const { trigger: variantUpdate } = useVariantUpdate();
  const { trigger: variantDelete } = useVariantDelete(variant?.id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    const variant = variants?.res_data?.result?.variants?.public;
    const variantList = variants?.res_data?.result?.variants;

    if (variantList) {
      setVariantList(Object.values(variantList));
    }

    setItem(variant);
  }, [variants]);

  //s: action ==================================================================================

  const onSubmit = async (data) => {
    if (mode === "insert") {
      console.log("check insert data: ", data);
      try {
        const response = await variantCreate(data);
        console.log("insert response: ", response);
      } catch (e) {
        console.log(e);
      }
    }

    if (mode === "update") {
      console.log("check update data: ", data);
      try {
        const response = await variantUpdate(data);
        console.log("update response: ", response);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onError = async (data) => {
    console.log("error: ", data);
  };

  const setItem = (variant) => {
    console.log("click data: ", variant);
    setVariant(variant);
    setValue("id", variant?.id);
    setValue("options.fit", variant?.options.fit);
    setValue("options.width", variant?.options.width);
    setValue("options.height", variant?.options.height);
    setValue("neverRequireSignedURLs", variant?.neverRequireSignedURLs);
  };

  const clickDelete = async (id) => {
    const response = await variantDelete(id);
    console.log("delete response: ", response);
  };

  //e: action ==================================================================================

  if (listLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container" style={{ marginTop: "180px" }}>
      <div className="row">
        <div className="col-lg-8" style={{ height: "500px" }}>
          <h1 className="mt-2">폴더명: {variant?.id}</h1>

          <JSONPretty
            className="mt-5"
            data={variant}
            theme={JSONPrettyMon}
          ></JSONPretty>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormInput
              {...register("id")}
              className=" mb-3"
              label="varinat id value"
            />
            <CFormSelect
              {...register("options.fit")}
              aria-label="select image fit Option"
              options={[
                "select image fit Option",
                { label: "scale-down", value: "scale-down" },
                { label: "contain", value: "contain" },
                { label: "cover", value: "cover" },
                { label: "pad", value: "pad" },
              ]}
            />

            <CFormInput
              {...register("options.width")}
              label="image width"
              placeholder="width"
            />

            <CFormInput
              {...register("options.height")}
              label="image height"
              placeholder="height"
            />

            <CFormCheck
              {...register("neverRequireSignedURLs")}
              className="mt-3 mb-3"
              label="neverRequireSignedURLs"
            />

            <CButton
              className="me-2"
              onClick={() => setMode("insert")}
              type="submit"
              color="primary"
            >
              입력
            </CButton>

            <CButton
              className="me-2"
              onClick={() => setMode("update")}
              type="submit"
              color="primary"
            >
              수정
            </CButton>

            <CButton
              onClick={() => clickDelete(variant?.id)}
              className="my-2"
              color="secondary"
            >
              삭제
            </CButton>
          </CForm>
        </div>

        <div className="col-lg-4">
          <div className="rounded">
            {variantsList?.map((item, index) => (
              <div key={index} className="mt-2">
                <div onClick={() => setItem(item)} className="ms-2">
                  <div className="d-flex">
                    <h5>{item.id}</h5>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudVariant;
