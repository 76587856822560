import { useEffect } from "react";
//lib
import { useNavigate } from "react-router-dom";
//session
import useSessionStore from "../store/useSessionStore";
import { useGoogleLogin } from "./api/LoginApi";

const GoogleLogin = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const googleCode = queryParams.get("code");

  const navigate = useNavigate();
  const { updateUserInfo } = useSessionStore();
  const { trigger: googleLogin } = useGoogleLogin(googleCode);

  const requestGoogleLogin = async () => {
    try {
      const { res_data } = await googleLogin(googleCode);

      updateUserInfo(res_data);
      res_data.jwtToken === "" ? navigate("/vertify") : navigate("/afterLogin");
    } catch (e) {
      console.log(e);
      navigate("/");
    }
  };

  useEffect(() => {
    requestGoogleLogin();
  }, []);
};

export default GoogleLogin;
