import React from "react";

import { useNavigate } from "react-router-dom";

//store
import useChatStore from "./store/useChatStore";

//core ui
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardImage,
  CCardBody,
  CCardTitle,
  CCardText,
  CButton,
} from "@coreui/react";

//나중에는 리스트로 데이터 만들어서 뿌릴것.
const dataList = [];

const ChatRooms = () => {
  return (
    <CContainer className="d-flex flex-wrap">
      <CRow className="my-5">
        <CCol xs="6">
          <CardItem
            title={"pizza"}
            content={"재미있는 ai 선생님과 함께 하는 영어수업"}
            imgSrc="https://img.freepik.com/free-vector/illustration-of-a-pizza-place_53876-43651.jpg"
          />
        </CCol>
        <CCol xs="6">
          <CardItem
            title={"길찾기"}
            content={"재미있는 ai 선생님과 함께 하는 영어수업"}
            imgSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeTMFq7wKjEGduUBCvDPVs7pP6IZ2Gm8IMEw&usqp=CAU"
          />
        </CCol>
      </CRow>
      <CRow className="my-5">
        <CCol xs="6">
          <CardItem
            title={"택시타기"}
            content={"재미있는 ai 선생님과 함께 하는 영어수업"}
            imgSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvKvV6Skj5XJzqxKZ9RwcGuAvuK0Y0nk7etw&usqp=CAU"
          />
        </CCol>
        <CCol xs="6">
          <CardItem
            title={"공항체크인"}
            content={"재미있는 ai 선생님과 함께 하는 영어수업"}
            imgSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyA5_TrDhKaV06js1C8DX6LpNpzDB-GSw20w&usqp=CAU"
          />
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default ChatRooms;

const CardItem = ({ title, content, imgSrc }) => {
  const { setNaviState } = useChatStore();
  const navigate = useNavigate();

  const goToChat = () => {
    navigate("/chats/ai");
    setNaviState({ title });
  };

  return (
    <>
      <CCard className="d-flex" style={{ width: "10rem" }}>
        <CCardImage
          style={{ objectFit: "cover", height: "100px" }}
          src={imgSrc}
        />
        <CCardBody>
          <CCardTitle>{title}</CCardTitle>
          <CCardText>{content}</CCardText>
          <CButton onClick={goToChat} color="primary" href="#">
            지금 바로 학습하러 가기
          </CButton>
        </CCardBody>
      </CCard>
    </>
  );
};
