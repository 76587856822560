import React from "react";
import ReactDOM from "react-dom/client";
import "@coreui/coreui/dist/css/coreui.min.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";

//rotuer lib
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//component
import App from "./App";
import KakaoLogin from "./view/login/KakaoLogin";
import NaverLogin from "./view/login/NaverLogin";
import GoogleLogin from "./view/login/GoogleLogin";
import Join from "./view/page/Join";
import AfterLogin from "./view/page/AfterLogin";
import AuthNumberPage from "./view/page/AuthNumberPage";
import PlatLogin from "./view/login/PlatLogin";
import Logout from "./view/login/Logout";
import VertifyPage from "./view/login/VertifyPage";
import CloudStream from "./view/stream/CloudStream";
import CloudImage from "./view/cloudImage/CloudImage";
import CloudVariant from "./view/cloudVariant/CloudVariant";
import Toss from "./view/toss/Toss";
import PaySuccess from "./view/toss/PaySuccess";
import PayFail from "./view/toss/PayFail";
import TalkImage from "./view/talkImage/TalkImage";
import TossCancel from "./view/toss/TossCancel";
import MemberLogs from "./view/memberLog/MemberLogs";
import CloudAudio from "./view/cloudAudio/CloudAudio";
import Chat from "./view/chat/Chat";
import ChatRooms from "./view/chat/ChatRooms";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/kakaoLogin",
    element: <KakaoLogin />,
  },
  {
    path: "/naverLogin",
    element: <NaverLogin />,
  },
  {
    path: "/login",
    element: <div>Login</div>,
  },
  {
    path: "/afterLogin",
    element: <AfterLogin />,
  },
  {
    path: "/auth_page",
    element: <AuthNumberPage />,
  },
  {
    path: "/googleLogin",
    element: <GoogleLogin />,
  },
  {
    path: "/join",
    element: <Join />,
  },
  {
    path: "/platLogin",
    element: <PlatLogin />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/vertify",
    element: <VertifyPage />,
  },
  {
    path: "/talk/image",
    element: <TalkImage />,
  },
  {
    path: "/stream",
    element: <CloudStream />,
  },
  {
    path: "/image",
    element: <CloudImage />,
  },
  {
    path: "/variant",
    element: <CloudVariant />,
  },
  {
    path: "/toss",
    element: <Toss />,
  },
  {
    path: "/pay/success",
    element: <PaySuccess />,
  },
  {
    path: "/pay/fail",
    element: <PayFail />,
  },
  {
    path: "/toss/cancel",
    element: <TossCancel />,
  },
  {
    path: "/member/logs",
    element: <MemberLogs />,
  },
  {
    path: "/audio",
    element: <CloudAudio />,
  },
  {
    path: "/chats/ai",
    element: <Chat />,
  },
  {
    path: "/chats",
    element: <ChatRooms />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
