import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../../common/axiosConfig";

export const useVariantList = () => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(`/api/variants`, fetcher);
  return { data, isLoading, error, mutate };
};

export const useVariantDetail = (id) => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(
    id ? `/api/variant/detail` : null,
    fetcher
  );
  return { data, isLoading, error, mutate };
};

export const useVariantCreate = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    `/api/variant/create`,
    fetcher
  );
  return { trigger, isMutating };
};

export const useVariantUpdate = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    `/api/variant/update`,
    fetcher
  );
  return { trigger, isMutating };
};

export const useVariantDelete = (id) => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    id ? `/api/variant/delete` : null,
    fetcher
  );
  return { trigger, isMutating };
};
