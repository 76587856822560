import React, { useState, useEffect } from "react";

//core ui componets
import {
  CForm,
  CButton,
  CFormInput,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";

//lib
import { useForm } from "react-hook-form";

//server
import {
  useMemberLogs,
  useSearchMemberByEmail,
  useMemberDelete,
  useMemberUpdate,
} from "./api/MemberLogsApi";

const MemberLogs = () => {
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [activeId, setActiveId] = useState("");

  const { data: members, isLoading, mutate } = useMemberLogs();
  const { trigger: getMemberWithEmail } = useSearchMemberByEmail();
  const { trigger: memberDelete } = useMemberDelete();
  const { trigger: memberUpdate } = useMemberUpdate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
    reset,
    resetField,
    control,
  } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    members && setMemberList(members?.res_data);
  }, [isLoading, members]);

  // handleEvent s: ========================================================================================

  const handleKeyEvent = async (e) => {
    if (e.key === "Enter") {
      try {
        const response = await getMemberWithEmail({ email: e.target.value });
        if (e.target.value === "") {
          setMemberList(members.res_data);
        } else {
          setMemberList(response.res_data);
        }
      } catch (error) {
        console.error("handleKeyEvent: ", error);
      }
    }
  };

  const clickSearch = async () => {
    try {
      const response = await getMemberWithEmail({ email: search });
      if (search === "") {
        setMemberList(members.res_data);
      } else {
        setMemberList(response.res_data);
      }
    } catch (error) {
      console.error("clickSearch: ", error);
    }
  };

  const clickActive = (item) => {
    setActiveId(item.id);
    setName(item.username);
  };

  const clickDeleteMember = async (item) => {
    try {
      const response = await memberDelete(item);
      console.log(response.res_data);
      mutate();
      window.alert(`${response.res_data.deletedCount}건 삭제 성공`);
    } catch (error) {
      console.error("clickDeleteMember error: ", error);
    }
  };

  const clickUpdateMember = async (item) => {
    try {
      const response = await memberUpdate({
        ...item,
        username: name,
      });
      console.log(response.res_data);
      mutate();
      window.alert(`${response.res_data.modifiedCount}건 update 성공`);
    } catch (error) {
      console.error("clickDeleteMember error: ", error);
    }
  };

  const handleUserName = async (e, item) => {
    if (e.key === "Enter") {
      try {
        const response = await memberUpdate({
          ...item,
          username: e.target.value,
        });
        console.log("handleOnKeyDown Response: ", response);
        window.alert(`${response.res_data.modifiedCount}건 update 성공`);
      } catch (error) {
        console.error("handleOnKeyDown: ", error);
      }
    }
  };

  // handleEvent e: ========================================================================================

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "100px" }}
      >
        <div className="d-flex flex-column">
          <h1>로그인 사용자 기록을 검색할 수 있습니다.</h1>
          <br />
          <div className="d-flex">
            <CFormInput
              className="w-75"
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyEvent}
            ></CFormInput>
            <CButton onClick={clickSearch} className="mx-2 w-25">
              검색
            </CButton>
          </div>
          <div className="mb-5 mx-2" style={{ fontSize: "0.9em" }}>
            * 이메일 기준으로 검색해주세요
          </div>
          <div>
            <CListGroup>
              {memberList.map((item) => (
                <CListGroupItem
                  onClick={() => clickActive(item)}
                  key={item.id}
                  active={item.id === activeId}
                >
                  <div>
                    <CFormInput
                      // {...register(`username`)}
                      onChange={(e) => setName(e.target.value)}
                      onKeyDown={(e) => handleUserName(e, item)}
                      disabled={item.id !== activeId}
                      defaultValue={item.username}
                      placeholder={"사용자 이름..."}
                    />
                  </div>

                  <div>email: {item.email}</div>
                  <div>loginType: {item.loginType}</div>
                  <div>loginTime: {item.loginTime}</div>
                  {item.id === activeId && (
                    <div className="my-2">
                      <CButton
                        onClick={() => clickUpdateMember(item)}
                        className="mx-2"
                        color="info"
                      >
                        수정
                      </CButton>
                      <CButton
                        onClick={() => clickDeleteMember(item)}
                        color="danger"
                      >
                        삭제
                      </CButton>
                    </div>
                  )}
                </CListGroupItem>
              ))}
            </CListGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberLogs;
