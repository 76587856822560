import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import pythonApi from "../../../common/pythonApiConfig";

export const useChat = (state) => {
  console.log("usechat state: ", state);
  const fetcher = (arg) => pythonApi.get(arg).then((res) => res.data);
  const { data, error, isLoading } = useSWR(
    state ? `/goals/${state.title}` : null,
    fetcher
  );
  return { data, isLoading, error };
};

//파이선 서버에 특정 미션을 수행했는지 체크하는 api
//chat api 이후 따라 다녀야하는 api (해당 내용이 목표를 달성했는지 확인해야하니.)
export const useCheckGoals = () => {
  const fetcher = (url, { arg }) =>
    pythonApi.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/check_goals/pizza", fetcher);
  return { trigger, isMutating };
};

// 사용자의 오디오 파일이 메세지로 반환되는 api
export const useTranscribe = () => {
  const fetcher = (url, { arg }) =>
    pythonApi.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/transcribe", fetcher);
  return { trigger, isMutating };
};

//사용자가 보낸 오디오 파일 혹은 메세지에 대한 답변 api
export const useAiChat = () => {
  const fetcher = (url, { arg }) =>
    pythonApi.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/chat", fetcher);
  return { trigger, isMutating };
};

// curl -X 'POST' \
//   'https://ai.teamsoft.kr/check_goals/pizza' \
//   -H 'accept: application/json' \
//   -H 'Content-Type: application/json' \
//   -d '{
//   "messages": [
//     {
//       "role": "user",
//       "content": "pizza"
//     }
//   ]
// }'
