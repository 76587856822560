import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const sessionData = JSON.parse(sessionStorage.getItem("session-storage"));
    const jwtToken = sessionData?.state?.userInfo?.jwtToken;
    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    console.log("글로벌 설정 응답 체킹", response);
    if (response.status === 214) {
      window.alert("만료된 계정입니다. 다시 로그인 해주세요.");
      window.location.href = "/";
    }

    return response;
  },
  (error) => {
    console.log("공통 axios config =>", error);
    if (error.response.data.res_code === "4001") {
      window.alert("세션이 만료되어 로그인화면으로 이동합니다.");
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default api;
