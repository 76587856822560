import React, { useEffect } from "react";
//lib
import { useNavigate } from "react-router-dom";
//session
import useSessionStore from "../store/useSessionStore";
//swr
import { useLogout } from "../api/AfterLoginApi";

const Logout = () => {
  //logout api 호출 하는곳

  const { userInfo } = useSessionStore();
  const { trigger: logoutTrigger } = useLogout();
  const navigate = useNavigate();

  const logout = async () => {
    const response = await logoutTrigger(userInfo);

    console.log(response);
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    logout();
  }, []);
  return <div>logout page</div>;
};

export default Logout;
