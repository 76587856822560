import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useSessionStore = create(
  persist(
    (set, get) => ({
      userInfo: {
        username: "방문자",
        email: "",
        jwtToken: "",
        loginType: "",
        memberId: 0,
      },
      updateUserInfo: (userInfo) =>
        set((state) => ({
          userInfo: {
            memberId: userInfo.memberId,
            username: userInfo.username,
            jwtToken: userInfo.jwtToken,
            email: userInfo.email,
            loginType: userInfo.loginType,
          },
        })),

      initUserInfo: () =>
        set((state) => ({
          userInfo: {
            memberId: 0,
            username: "방문자",
            jwtToken: "",
            email: "",
            loginType: "",
          },
        })),
    }),
    {
      name: "session-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useSessionStore;
