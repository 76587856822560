import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../../common/axiosConfig";

export const useEmailCertification = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    "/api/oauth/email/certification",
    fetcher
  );
  return { trigger, isMutating };
};

export const useEmailVerify = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    "/api/oauth/email/verify",
    fetcher
  );
  return { trigger, isMutating };
};

export const useLogin = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/oauth/join", fetcher);
  return { trigger, isMutating };
};

export const useGoogleLogin = (code) => {
  const fetcher = (url) => api.post(url).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    code ? `/api/oauth/google?code=${code}` : null,
    fetcher
  );
  return { trigger, isMutating };
};

export const useKakaoLogin = (code) => {
  const fetcher = (url) => api.post(url).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    code ? `/api/oauth/kakao?code=${code}` : null,
    fetcher
  );
  return { trigger, isMutating };
};

export const useNaverLogin = (code, state) => {
  const fetcher = (url) => api.post(url).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    code ? `/api/oauth/naver?code=${code}&state=${state}` : null,
    fetcher
  );
  return { trigger, isMutating };
};
