import React, { useState, useEffect } from "react";

import { CForm, CButton, CFormInput, CFormCheck } from "@coreui/react";

import { useUserList } from "../api/AfterLoginApi";
import {
  useTossPayList,
  useTossPayResponse,
  useTossCancel,
} from "./api/TossApi";

//lib
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/themes/monikai.css";

const TossCancel = () => {
  const [memberId, setMemberId] = useState(0);
  const [tossPayInfo, setTossPayInfo] = useState({});

  const { data: userList, isLoading } = useUserList();
  const { data: payList, isLoading: payLoading } = useTossPayList(memberId);
  const { trigger: requestTossPayInfo } = useTossPayResponse();
  const { trigger: requestTossPayCancel } = useTossCancel();

  const clickPayKey = async (paymentKey) => {
    try {
      const response = await requestTossPayInfo({ paymentKey });
      console.log("tossPayInfo response: ", response.res_data);
      setTossPayInfo(response.res_data);
    } catch (error) {
      console.error("requestTossPayInfo error: ", error);
    }
  };

  const clickPayCancel = async (item) => {
    try {
      const response = await requestTossPayCancel({
        cancelReason: "고객변심",
        paymentKey: item.paymentKey,
      });

      console.log("clickPayCancel response: ", response.res_data);
    } catch (error) {
      console.error("clickPayCancel error: ", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="d-flex justify-content-center mt-5"
      style={{ marginTop: "200px" }}
    >
      <div className="d-flex flex-column">
        {userList.res_data.map((item) => (
          <div onClick={() => setMemberId(item.member_id)} key={item.member_id}>
            {item.email}
          </div>
        ))}
      </div>
      <div className="d-flex flex-column">
        {payList &&
          payList.res_data.map((item) => (
            <div key={item.orderId}>
              <div
                onClick={() => clickPayKey(item.paymentKey)}
                className="d-flex"
              >
                paymentKey: {item.paymentKey}
              </div>
              <CButton onClick={() => clickPayCancel(item)}>
                결재 취소, 환불
              </CButton>
            </div>
          ))}
        <div>
          <JSONPretty
            className="mt-5"
            data={tossPayInfo}
            theme={JSONPrettyMon}
          ></JSONPretty>
        </div>
      </div>
    </div>
  );
};

export default TossCancel;
