import React, { useState, useEffect } from "react";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { useCloudAudio } from "./api/CloudAudioApi";

//react music player 사용하여 audio 코드 작성할것.
//yarn add react-h5-audio-player

const S3 = new S3Client({
  region: "auto",
  endpoint: `https://c043e91c4226a6a62c00420249cc9aed.r2.cloudflarestorage.com`,
  credentials: {
    accessKeyId: "d9732bda7087b45d2f1317917a38ee3d",
    secretAccessKey:
      "3d0a932bf6c46e8de2ed52712f6c09b813f9438572cad22e322a08534eb68558",
  },
});

const CloudAudio = () => {
  const [audioUrls, setAudioUrls] = useState([]);
  const { data: audios, isLoading, mutate } = useCloudAudio();

  useEffect(() => {
    fetchAudioUrls();
  }, [audios]);

  const fetchAudioUrls = async () => {
    if (!audios) return;

    const signedUrls = await Promise.all(
      audios.res_data.objectSummaries.map(async (audio) => {
        const signedUrl = await getSignedUrl(
          S3,
          new GetObjectCommand({ Bucket: audio.bucketName, Key: audio.key }),
          { expiresIn: 3600 }
        );
        return { key: audio.key, url: signedUrl };
      })
    );

    setAudioUrls(signedUrls);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "200px" }}
      >
        <div className="d-felx flex-column">
          {audioUrls &&
            audioUrls?.map((item) => (
              <div key={item.key}>
                <h4>{item.key}</h4>
                <div className="d-flex my-4">
                  <audio controls>
                    <source src={item.url} type="audio/mpeg" />
                  </audio>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default CloudAudio;
