import React, { useState, useEffect } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CForm, CButton, CFormCheck, CFormSelect } from "@coreui/react";

const PayFail = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const location = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    //spring server쪽 문제 발생 navigate 함수로 error 객체 set 하는 코드
    if (location) {
      setData(location.state.response);
    }
  }, []);

  //우리쪽 spring confirm 서버 에러시
  if (data) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "250px" }}
      >
        <div className="d-felx flex-column">
          <h1>결재실패</h1>
          <h3>{data?.res_msg}</h3>
          <CButton onClick={() => navigate("/toss")}>
            다시 결재하러 가기
          </CButton>
        </div>
      </div>
    );
  }

  //toss server errror 시
  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "250px" }}
    >
      <div className="d-felx flex-column">
        <h1>결재실패</h1>
        <h3>{searchParams.get("code")}</h3>
        <h3>{searchParams.get("message")}</h3>
        <h3>{searchParams.get("orderId")}</h3>
        <CButton onClick={() => navigate("/toss")}>다시 결재하러 가기</CButton>
      </div>
    </div>
  );
};

export default PayFail;
