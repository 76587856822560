import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { CForm, CButton, CFormCheck, CFormSelect } from "@coreui/react";

//lib
import { useForm } from "react-hook-form";
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/themes/monikai.css";

//Date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//uppy
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import DragDrop from "@uppy/drag-drop";
import XHR from "@uppy/xhr-upload";
import { Dashboard } from "@uppy/react";

//server
import {
  useImageList,
  useImageDetail,
  useImageDelete,
  useImageUpload,
  useImageUpdate,
  useImageKey,
} from "./api/CloudImageApi";
import axios from "axios";

const CloudImage = () => {
  const [uppy] = useState(() =>
    new Uppy()
      .use(Webcam)
      .use(DragDrop)
      .use(XHR, {
        endpoint: `${process.env.REACT_APP_API_SERVER}/api/image/uppy`,
        // limit: 10 * 1024 * 1024, // 10MB를 바이트 단위로 설정
      })
  );

  const [image, setImage] = useState({});
  const [imageSrc, setImageSrc] = useState("");
  const [variant, setVariant] = useState("thumbnail");

  const navigate = useNavigate();

  const currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() + 30);

  const [expiry, setExpiry] = useState(currentDate);

  //data
  const { data: images, mutate, isLoading } = useImageList();
  const { data: imageDetail } = useImageDetail(image?.id);

  //trigger
  const { trigger: deleteImage } = useImageDelete(image.id);
  const { trigger: uploadImage } = useImageUpload();
  const { trigger: updateImage } = useImageUpdate();
  const { trigger: getImageKey } = useImageKey();

  const imageList = images?.res_data?.result?.images;

  //callback s: action ==================================================================================

  const postUploadImage = async (file, url) => {
    try {
      const response = await uploadImage();

      const formData = new FormData();
      formData.append("file", file?.data);

      await axios.post(response?.res_data?.result?.uploadURL, formData);
      mutate();
    } catch (e) {
      console.log("postUploadImage fail: ", e);
    }
  };

  //callback e: action ==================================================================================

  //life cycle s: =======================================================================================
  useEffect(() => {
    uppy.on("upload-success", postUploadImage);

    return () => {
      uppy.off("upload-success", postUploadImage);
    };
  });

  useEffect(() => {
    if (images) {
      setImage(imageList[0]);
    }
  }, [images]);
  //life cycle e: =======================================================================================

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  //s: action =========================================================================================

  const onSubmit = async (data) => {
    console.log("submit data => ", data);

    if (!data.file[0]) {
      window.alert("이미지를 선택해주세요");
      return;
    }

    const sendData = {
      expiry: expiry.toISOString(),
      requireSignedURLs: data.requireSignedURLs,
    };

    try {
      const response = await uploadImage(sendData);
      console.log(response.res_data);

      if (response.res_data.success) {
        window.alert("이미지를 성공적으로 업로드 했습니다.");
      } else {
        window.alert(
          response.res_data.errors[0].message +
            ": URL 유효기간 설정 범위를 넘어갔습니다. 최대 6시간 이상 가능"
        );
      }

      const formData = new FormData();
      formData.append("file", data.file[0]);

      const uploadResponse = await axios.post(
        response.res_data.result.uploadURL,
        formData
      );

      setValue("file", "");
      mutate();
      console.log("after upload response :", uploadResponse);
    } catch (e) {
      console.log("direct upload api fail", e);
    }
  };

  const clickDelete = async () => {
    try {
      const response = await deleteImage();
      console.log(response);
      if (response.res_data.success) {
        window.alert("성공적으로 삭제했습니다.");
      }
      mutate();
    } catch (e) {
      console.log("clickDelete ==> error: ", e);
    }
  };

  const clickSave = async () => {
    if (image) {
      console.log(image);

      const sendData = {
        id: image.id,
        requireSignedURLs: getValues("requireSignedURLs"),
      };
      try {
        const response = await updateImage(sendData);
        console.log(response);
        if (response.res_data.success) {
          window.alert("저장되었습니다");
        }
        mutate();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const clickSetImage = async (item) => {
    setImage(item);
    setValue("requireSignedURLs", item.requireSignedURLs);
    const url = `https://imagedelivery.net/XJ0dKkCgMOZQs4Ecum2vlw/${item.id}/${variant}`;
    const newUrl = await generateSignedUrl(new URL(url));
    setImageSrc(newUrl);
  };

  //e: action ==================================================================================

  const EXPIRATION = 60 * 60 * 24; // 1 day

  const bufferToHex = (buffer) =>
    [...new Uint8Array(buffer)]
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("");

  const generateSignedUrl = async (url) => {
    const encoder = new TextEncoder();
    const { res_data: KEY } = await getImageKey();
    console.log("복호화된 key값: ", KEY);
    const secretKeyData = encoder.encode(KEY);
    const key = await crypto.subtle.importKey(
      "raw",
      secretKeyData,
      { name: "HMAC", hash: "SHA-256" },
      false,
      ["sign"]
    );

    // Attach the expiration value to the `url`
    const expiry = Math.floor(Date.now() / 1000) + EXPIRATION;
    url.searchParams.set("exp", expiry);
    // `url` now looks like
    // https://imagedelivery.net/cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

    const stringToSign = url.pathname + "?" + url.searchParams.toString();
    // for example, /cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

    // Generate the signature
    const mac = await crypto.subtle.sign(
      "HMAC",
      key,
      encoder.encode(stringToSign)
    );
    const sig = bufferToHex(new Uint8Array(mac).buffer);

    // And attach it to the `url`
    url.searchParams.set("sig", sig);
    return url;
    // return new Response(url);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container" style={{ marginTop: "180px" }}>
      <div className="row">
        <div className="col-lg-8" style={{ height: "500px" }}>
          <h1 className="mt-2">{image?.filename}</h1>

          {/* 
          이미지 사이즈 변환 2가지 방법

          1.  variants로 사이즈 변경방식
            각 이미지마다 clooudflare 에서 내가 생성한 variants 객체 생성해줌.
            현재 variants public, mobile, thumbnail 존재함 (생성, 삭제, 편집 가능)
            각 variants 사이즈가 설정되어있고 설정된 variants를 불러서 사용하는 것    

          2. URL 파라미터 변경방식
            variants 보다 더 다양하고 자세한 옵션 설정가능한 버전임. 
            뒤 파라미터를 통해 이미지 조절 가능
            상세한 옵션은 해당 링크로 확인
            NOTE * 파라미터 변경방식 사용시 기본적으로 옵션 1개 필수임 * 
            https://developers.cloudflare.com/images/transform-images/transform-via-url/
            URL
            https://imagedelivery.net/XJ0dKkCgMOZQs4Ecum2vlw/
            //src="<domain>/<hashcode>/<image-id>/<optioins>"
            도메인, hashcode 고정값임, 각 이미지 아이디, 옵션은 동적 변경

          3. 암호화 URL 시 이미지 변경방법
            1번의 variants로 사이즈 변경방식만 사용가능 
            URL을 통한 이미지 변경방식 사용불가
          */}

          {/* 1. variants로 사이즈 변경방식 */}
          <img
            alt="logo"
            src={`${image?.variants?.find((item) =>
              item.includes(`/${variant}`)
            )}`}
          ></img>

          {/* 2.URL 파라미터 변경방식 */}
          <img
            alt="logo"
            src={`https://imagedelivery.net/XJ0dKkCgMOZQs4Ecum2vlw/${image?.id}/width=50`}
          ></img>

          {/* 3.암호화 이미지 URL setImage 함수에서 설정되었음. */}
          <img alt="logo" src={`${imageSrc}`}></img>

          <CForm
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <CFormCheck
              {...register("requireSignedURLs")}
              className="mt-3 mb-3"
              label="requireSignedURLs"
            />

            {/* <div className="mb-2">
              <div className="mb-1">
                <span>URL 유효기간 설정</span>
              </div>
              <DatePicker
                selected={expiry}
                showTimeSelect
                onChange={(date) => setExpiry(date)}
                placeholder={"uploadExpiry"}
                dateFormat="yyyy-MM-dd HH:mm"
              />
            </div> */}

            <CFormSelect
              aria-label="select image variants"
              value={variant}
              onChange={(e) => setVariant(e.target.value)}
              options={[
                "select image variants",
                { label: "public", value: "public" },
                { label: "mobile", value: "mobile" },
                { label: "thumbnail", value: "thumbnail" },
              ]}
            />

            {/* <CFormInput
              {...register("file")}
              type="file"
              label="이미지 파일 선택"
              multiple
              accept=".png, .jpg"
            /> */}

            <Dashboard uppy={uppy} plugins={["Webcam", "DragDrop"]} />

            {/* <CButton type="submit">이미지 업로드</CButton> */}

            <CButton className="mx-2" onClick={clickSave} color="primary">
              저장
            </CButton>

            <CButton
              onClick={() => clickDelete(image.id)}
              className="my-2 me-2"
              color="secondary"
            >
              삭제
            </CButton>
            <CButton
              onClick={() => navigate("/afterLogin")}
              className="my-2"
              color="secondary"
            >
              뒤로가기
            </CButton>
          </CForm>

          <JSONPretty
            className="mt-5"
            data={imageDetail?.res_data}
            theme={JSONPrettyMon}
          ></JSONPretty>
        </div>

        <div className="col-lg-4">
          <div className="rounded">
            {imageList?.map((item) => (
              <div key={item.id} className="mt-2">
                <div onClick={() => clickSetImage(item)} className="ms-2">
                  <div className="d-flex">
                    <h5>{item.filename}</h5>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudImage;
