import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../../common/axiosConfig";

export const useMemberLogs = () => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(
    "/api/member/logs",
    fetcher
  );
  return { data, isLoading, error, mutate };
};

export const useMemberInfo = (email, loginType) => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    email && loginType
      ? `/api/member/find?email=${email}&loginType=${loginType}`
      : null,
    fetcher
  );
  return { trigger, isMutating };
};

export const useSearchMemberByEmail = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/member/findby`, fetcher);
  return { trigger, isMutating };
};

export const useMemberDelete = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/member/delete`, fetcher);
  return { trigger, isMutating };
};

export const useMemberUpdate = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/member/update`, fetcher);
  return { trigger, isMutating };
};
