import React, { useState, useEffect, useRef } from "react";

//core ui
import {
  CButton,
  CFormInput,
  CFormCheck,
  CCard,
  CCardBody,
  CInputGroup,
  CInputGroupText,
  CFormSwitch,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMicrophone } from "@coreui/icons";

//hooks
import useRecorder from "../record/useRecorder";
import useChatService from "./hook/useChatService";
//server
import { useChat } from "./api/ChatApi";
//store
import useChatStore from "./store/useChatStore";
//componets
import Typing from "react-typing-animation";
import BalloonAnimation from "./animation/BalloonAnimation";
import LoadingAnimation from "./animation/LoadingAnimation";

const Chat = () => {
  const { goalsList, aiContent, clearMessages, naviState } = useChatStore();

  useEffect(() => {
    //방 입장시 모든 메세지 내용 초기화
    //useState 으로 안하고 세션으로 메세지 관리 이유: store에 저장해서 다른 컴포넌트 사이에서 쉽게 업데이트 및 조회 하기 위함.
    clearMessages();
  }, []);

  const { data: missionList } = useChat(naviState);

  const {
    startRecording,
    stopRecording,
    isRecording,
    timestamp,
    activateRecorder,
    inActivateRecorder,
  } = useRecorder();

  const audioRef = useRef(null);

  const playAudio = async () => {
    //ai 응답 상태 있을 시에만 play 함수 작동
    if (aiContent !== "") {
      console.log("paly audio is working?");
      audioRef.current.play();
    }
  };

  const [isRecord, setIsRecord] = useState(false);
  const [isStreamAct, setIsStreamAct] = useState(false);

  //isStreamAct (recoder 활성화시에만. isRecord 상태 제어)
  const handleRecoder = () => {
    if (isStreamAct) {
      setIsRecord((pre) => !pre);
    } else {
      window.alert("녹음을 활성화 해주세요.");
    }
  };

  useEffect(() => {
    //isRecord 상태에 start, stop 정해짐 초기값 false
    //최초 렌더 시 stopRecoring 함수 실행 하지만 recorder 상태 Null 상태이므로 아무작동 안함.
    isRecord ? startRecording() : stopRecording();
  }, [isRecord]);

  //isStreamAct 상태 조작함.
  const handleActivateRecorder = (e) => {
    const isActivate = e.target.checked;
    setIsStreamAct(isActivate);
    if (isActivate) {
      //recorder 함수 useRecorder 객체에서 stream 다시 생성
      activateRecorder();
    } else {
      //useRecorder 함수의 recoder 상태 null update
      inActivateRecorder();
      //녹화 중지 상태 업데이트. isRecorder 상태 false 시 stopRecordering 함수 실행
      setIsRecord(false);
    }
  };

  return (
    <div className="d-flex" style={{ marginTop: "150px" }}>
      <div className="d-flex flex-column w-100">
        <div className="d-flex rounded rounded-bottom-0 border border-bottom-0 bg-primary text-white">
          <div className="d-flex justify-content-center align-items-center">
            <img
              alt="logg"
              width={40}
              height={40}
              className="ms-3 rounded-circle"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGNgswuXSqY1iaIBoc5926v3U_6TZK4GK0Rg&usqp=CAU"
            ></img>
            <h3 className="d-flex p-2 ms-2 mb-0">Speak Ai 단비</h3>
          </div>
          <div className="me-2 d-flex align-items-center">
            <CIcon
              icon={cilMicrophone}
              className={`p-1 rounded-circle bg-warning text-dark ${
                isRecording ? "bg-danger" : "bg-warning"
              }`}
              size="xxl"
              style={{ cursor: "pointer" }}
              onClick={() => handleRecoder()}
            />
          </div>
          <div className="ms-2 d-flex align-items-center">
            <CFormSwitch
              size="xl"
              label="녹음활성화"
              id="formSwitchCheckDefaultLg"
              onChange={(e) => handleActivateRecorder(e)}
            />
          </div>
        </div>
        <div className="d-flex flex-column rounded rounded-top-0 border">
          <audio key={timestamp} ref={audioRef}>
            <source
              src={`https://ai.teamsoft.kr/audio?timestamp=${timestamp}`}
              type="audio/mp3"
            />
          </audio>
          <MessageList style={{ height: "400px" }} playAudio={playAudio} />
          <div className="my-2 ">
            <MessageForm />
          </div>
          <div className="d-felx justify-content-center">
            <MissionBoxList missions={missionList} />
            <BalloonAnimation checkList={goalsList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

const MessageList = ({ playAudio }) => {
  //message List
  const { messages } = useChatService();
  //초기 [] 빈 배열값
  const scrollRef = useRef(null);

  useEffect(() => {
    //message 상태변경이 되면...스크롤 밑으로
    handleScroll();
  }, [messages]);

  const handleScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  if (messages.length === 0) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "400px" }}
      >
        <h3>단비와 대화를 시작해볼까요?</h3>
      </div>
    );
  }

  return (
    <div className="overflow-auto" style={{ height: "400px" }} ref={scrollRef}>
      {messages.map((item, index) => (
        <Message key={index} message={item} playAudio={playAudio}></Message>
      ))}
    </div>
  );
};

const Message = ({ message, playAudio }) => {
  //messageForm 에서는 해당 함수에서 실행 시켰기 때문에 원하는 state가 나왔지만 Message는 새로운 함수를 호출한것이기 때문에 상태가 독립적으로 나온다.
  const { aiContent } = useChatService();

  const isUser = message.role === "user";
  const isAssistant = message.role === "assistant";
  const isUpdatedAnswer = aiContent === message.content;

  return (
    <div
      className={`p-2 d-flex ${
        isUser ? "justify-content-end" : "justify-content-start"
      }`}
    >
      {isUser && (
        <CCard
          className="w-50 bg-primary text-white border-0"
          style={{ borderRadius: "10px 0px 10px 10px" }}
        >
          <CCardBody>
            <p className="m-0">
              <b>{message.role}: </b> {message.content}
            </p>
          </CCardBody>
        </CCard>
      )}

      {isAssistant && !isUpdatedAnswer && (
        <CCard
          className="w-50  bg-light text-dark border-0"
          style={{ borderRadius: "0px 10px 10px 10px" }}
        >
          <CCardBody>
            <p className="m-0">
              <b>{message.role}: </b> {message.content}
            </p>
          </CCardBody>
        </CCard>
      )}

      {isAssistant && isUpdatedAnswer && (
        <Typing speed={50} onStartedTyping={() => playAudio()}>
          <CCard
            className="w-80  bg-light text-dark border-0"
            style={{ borderRadius: "0px 10px 10px 10px" }}
          >
            <CCardBody>
              <p className="m-0">
                <b>{message.role}: </b> {message.content}
              </p>
            </CCardBody>
          </CCard>
        </Typing>
      )}
    </div>
  );
};

const MessageForm = () => {
  //messages, message 상태 이름이 너무 비슷함
  //listState, String State
  const [message, setMessage] = useState("");
  const [isTalk, setIsTalk] = useState(false);
  const { setMessages, aiChat, checkGoalsList, isAiTaking } = useChatService();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isAiTaking) {
      return;
    }
    setMessage("");
    console.log(message);
    setMessages({
      role: "user",
      content: message,
    });

    aiChat();
    checkGoalsList();
  };

  useEffect(() => {
    if (isAiTaking) {
      setMessage("ai가 말하고 있어요 잠시만 기다려주세요.");
      setIsTalk(true);
    } else {
      setIsTalk(false);
      setMessage("");
    }
  }, [isAiTaking]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center">
        <CFormInput
          type="text"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          className="w-75"
          disabled={isTalk}
        />
        <CButton type="submit" className="ms-2">
          Send
        </CButton>
      </div>
    </form>
  );
};

const MissionBoxList = ({ missions }) => {
  //missions is String list
  //부모 상태에 따라 하위 컴포넌트도 reRendering 되는중.
  return (
    <div className="d-flex flex-column">
      {missions?.map((mission, index) => (
        <div key={index}>
          <MissionBox mission={mission} />
        </div>
      ))}
    </div>
  );
};

// {
//   "goal_list": [
//     {
//       "goal": "Check if you ordered pizza",
//       "goal_number": 0,
//       "accomplished": true
//     },
//     {
//       "goal": "Check if you ordered coke",
//       "goal_number": 1,
//       "accomplished": false
//     }
//   ]
// }

const MissionBox = ({ mission }) => {
  //user sholud not handle check box ai tutor will judge the mission
  //depends on ai judge check state will change
  const [check, setCheck] = useState(false);

  const { goalsList } = useChatStore();

  console.log("mission check:", mission);
  console.log("check GoalsList", goalsList);

  useEffect(() => {
    //checkGoalsList state 변경 감지하여 check state 변경
    goalsList?.forEach(
      (missionItem) =>
        missionItem.goal === mission && setCheck(missionItem.accomplished)
    );
  }, [goalsList, mission]);

  const handleCheckBox = () => {};

  return (
    <CInputGroupText className="my-2 mx-3">
      <CFormCheck checked={check} onChange={handleCheckBox} label={mission} />
    </CInputGroupText>
  );
};

const Checkbox = ({ checked, onChange }) => {
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{
          width: "1.5rem",
          height: "1.5rem",
          appearance: "none",
          borderRadius: "50%",
          backgroundColor: "#ffffff",
          transition: "background 300ms",
          cursor: "pointer",
        }}
      />
      <span
        style={{
          content: "''",
          color: "transparent",
          display: "block",
          width: "inherit",
          height: "inherit",
          borderRadius: "inherit",
          border: "0",
          backgroundColor: "transparent",
          backgroundSize: "contain",
          boxShadow: "inset 0 0 0 1px #ccd3d8",
        }}
      ></span>
    </label>
  );
};
