import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../../common/axiosConfig";

export const useVideoList = () => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(`/api/videos`, fetcher);
  return { data, isLoading, error, mutate };
};

export const useVideoDetail = (id) => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(
    id ? `/api/video/detail?id=${id}` : null,
    fetcher
  );
  return { data, isLoading, error };
};

export const useVideoDetailV2 = (id) => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(
    id ? `/api/video/v2/detail?id=${id}` : null,
    fetcher
  );
  return { data, isLoading, error };
};

export const useVideoEdit = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/video/edit`, fetcher);
  return { trigger, isMutating };
};

export const useVideoDelete = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/video/delete`, fetcher);
  return { trigger, isMutating };
};

export const useVideoRequest = (id) => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    id ? `/api/video/token?id=${id}` : null,
    fetcher
  );
  return { trigger, isMutating };
};
