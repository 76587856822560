import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CForm,
} from "@coreui/react";

//lib
import { useForm } from "react-hook-form";

//server
import {
  useEmailCertification,
  useEmailVerify,
  useLogin,
} from "../login/api/LoginApi";

const Join = () => {
  const navigate = useNavigate();
  const history = createBrowserHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm();

  const [emailCheck, setEmailCheck] = useState(false);
  const [timer, setTimer] = useState(0); // 초기값은 0으로 설정
  const [timerRunning, setTimerRunning] = useState(false); // 타이머가 실행 중인지 여부
  const [valid, setValid] = useState(); //이메일 검증 통과 여부

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const { trigger: sendEmail } = useEmailCertification();
  const { trigger: verifyEmail } = useEmailVerify();
  const { trigger: login } = useLogin();

  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const onSubmit = async (data) => {
    console.log("submit data => ", data);

    if (valid !== 200) {
      window.alert("이메일 검증 먼저해주세요.");
      return;
    }

    try {
      const { res_code } = await login(data);
      res_code === "200" && navigate("/platLogin");
    } catch ({ response: { data } }) {
      setError("root.serverError", {
        type: data.res_code,
        message: data.res_msg,
      });
    }
  };

  const emailCertification = async () => {
    if (!isValidEmail(getValues("email"))) {
      window.alert("유효하지 않은 이메일 주소입니다.");
      return;
    }

    const data = {
      email: getValues("email"),
    };

    try {
      const response = await sendEmail(data);
      console.log("response emailCertification => ", response);

      setEmailCheck(true);
      setTimer(180);
      setTimerRunning(true);

      window.alert(response.res_msg);
    } catch ({ response: { data } }) {
      setError("root.serverError", {
        type: data.res_code,
        message: data.res_msg,
      });
    }
  };

  const vertifyEmail = async () => {
    const data = {
      email: getValues("email"),
      authNumber: getValues("authNumber"),
    };

    try {
      const { res_code } = await verifyEmail(data);
      setValid(Number(res_code));
    } catch ({ response: { data } }) {
      setError("root.serverError", {
        type: data.res_code,
        message: data.res_msg,
      });
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    const handleBack = () => {
      // 뒤로가기 버튼 클릭 시 실행할 작업을 이 곳에 작성합니다.
      console.log("뒤로가기 버튼이 클릭되었습니다.");
    };

    const unlisten = history.listen((location, action) => {
      if (action === "POP") {
        handleBack();
      }

      if (action === "PUSH") {
        handleBack();
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (timer <= 0) {
      setTimerRunning(false);
    }
  }, [timer]);

  useEffect(() => {
    let interval;
    if (timerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerRunning, timer]);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "300px" }}
    >
      <div className="w-25">
        <h1 className="mb-2 ">회원가입</h1>
        <hr />
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <CInputGroup className="mb-3">
            <CInputGroupText>이름</CInputGroupText>
            <CFormInput
              {...register("username", {
                required: "멋진 이름을 입력해주세요.",
              })}
              placeholder="이름을 입력해주세요."
            />
          </CInputGroup>
          {errors.username && <div>{errors.username?.message}</div>}
          <CInputGroup className="mb-3">
            <CInputGroupText>비밀번호</CInputGroupText>
            <CFormInput
              {...register("password", {
                required: "비밀번호는 필수 입력사항입니다.",
              })}
              type="password"
              placeholder="비밀번호를 입력해주세요."
            />
          </CInputGroup>
          {errors.password && <div>{errors.password?.message}</div>}
          <CInputGroup className="mb-3">
            <CInputGroupText>비밀번호 재확인</CInputGroupText>
            <CFormInput
              {...register("password1", {
                validate: (value) =>
                  value === getValues("password") || "비번이 같아야합니다.",
              })}
              type="password"
              placeholder="비밀번호를 입력해주세요."
            />
          </CInputGroup>
          {errors && <span className="">{errors.password1?.message}</span>}

          <hr />

          <CInputGroup className="mb-3">
            <CInputGroupText>이메일</CInputGroupText>
            <CFormInput
              {...register("email", {
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "유효한 이메일 주소를 입력해주세요.",
                },
              })}
              type="email"
              placeholder="이메일을 입력해주세요"
              disabled={timerRunning}
            />
            <CButton
              onClick={emailCertification}
              disabled={timerRunning || valid === "OK"}
            >
              이메일 전송
            </CButton>
          </CInputGroup>
          {valid === 200 && <div>인증되었습니다.</div>}
          {emailCheck && (
            <CInputGroup className="mb-1">
              <CInputGroupText>인증번호</CInputGroupText>
              <CFormInput
                {...register("authNumber")}
                placeholder="숫자 6자리를 입력해주세요."
                disabled={valid === 200}
              />
              <CButton disabled={valid === 200} onClick={vertifyEmail}>
                검증
              </CButton>
              {errors?.root && (
                <div className="">{errors.root.serverError.message}</div>
              )}
            </CInputGroup>
          )}

          <div className="mb-3 me-2 d-flex justify-content-start align-items-center">
            <span>
              {timerRunning && valid !== 200 && (
                <div>{`남은 시간: ${formatTime(timer)}`}</div>
              )}
            </span>
          </div>
          <CInputGroup className="mb-3">
            <CInputGroupText>전화번호</CInputGroupText>
            <CFormInput
              {...register("tellNo", {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "숫자만 입력해주세요.",
                },
                required: "전화번호는 필수입력 사항입니다.",
              })}
              placeholder="ex) 010123456778"
              type="tel"
            />
          </CInputGroup>
          {errors && <div>{errors.tellNo?.message}</div>}
          <div className="d-flex justify-content-end">
            <CButton type="submit">가입</CButton>
          </div>
        </CForm>
      </div>
    </div>
  );
};

export default Join;
