import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../common/axiosConfig";

export const useUser = () => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading } = useSWR("/api/users", fetcher);
  return { data, isLoading, error };
};

export const useUserList = () => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading } = useSWR("/api/user/list", fetcher);
  return { data, isLoading, error };
};

export const useDeviceList = (memberId) => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading } = useSWR(
    memberId ? `/api/devices?memberId=${memberId}` : null,
    fetcher
  );
  return { data, isLoading, error };
};

export const useLogout = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/logout", fetcher);
  return { trigger, isMutating };
};

export const useKakaoWithdraw = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/withdraw", fetcher);
  return { trigger, isMutating };
};

export const useGetUser = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/users", fetcher);
  return { trigger, isMutating };
};

export const usePlatLogin = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    "/api/oauth/plat/login",
    fetcher
  );
  return { trigger, isMutating };
};

export const useVertifyLogin = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/oauth/vertify", fetcher);
  return { trigger, isMutating };
};

export const useNaverUnlink = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/naver/unlink", fetcher);
  return { trigger, isMutating };
};

export const useGoogleUnlink = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/google/unlink", fetcher);
  return { trigger, isMutating };
};

export const useKakaoTalk = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    "/api/kakao/friends/talk",
    fetcher
  );
  return { trigger, isMutating };
};
