import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { CForm, CButton, CFormInput, CFormCheck } from "@coreui/react";

//lib
import { useForm } from "react-hook-form";
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/themes/monikai.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//server
import {
  useVideoList,
  useVideoDelete,
  useVideoDetail,
  useVideoEdit,
  useVideoRequest,
} from "./api/StreamApi";

const CloudStream = () => {
  const navigate = useNavigate();
  const { data: videos, mutate, isLoading } = useVideoList();
  const videoList = videos?.res_data?.result;

  const [video, setVideo] = useState({});

  const { data: videoDetail } = useVideoDetail(video?.uid);

  const [scheduledDeletion, setScheduledDeletion] = useState(new Date());
  const [uploadExpiry, setUploadExpiry] = useState(new Date());
  const [token, setToken] = useState("");

  const { trigger: deleteVideo } = useVideoDelete();
  const { trigger: editVideo } = useVideoEdit();
  const { trigger: requestVideo } = useVideoRequest(video?.uid);

  useEffect(() => {
    const videoItem = videos?.res_data?.result[0];
    if (videoItem) {
      setVideo(videoItem);
      console.log(video);
    }
  }, [videos]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  //s: action ==================================================================================

  const clickDelete = async (id) => {
    try {
      await deleteVideo({ id });
      mutate();
    } catch (e) {
      console.log("clickDelete ==> error: ", e);
    }
  };

  const onSubmit = async (data) => {
    console.log("data 타입 체크", data);
    try {
      data["id"] = video.uid;
      data["allowedOrigins"] = data.allowedOrigins.flat();

      if (data["allowedOrigins"][0] === "") {
        data["allowedOrigins"] = [];
      }

      const response = await editVideo(data);
      const parseResponse = JSON.parse(response.res_data);

      if (parseResponse.success === true) {
        window.alert("성공적으로 저장했습니다.");
      } else {
        window.alert("문제 발생");
      }
    } catch (e) {
      console.log("onSubmit-error", e);
    }
  };

  const onError = async (data) => {
    console.log("onError ==> error: ", data);
  };

  const onChange = (data) => {
    console.log("scheduledDeletion data", data);
    setScheduledDeletion(data);
  };

  const onChange1 = (data) => {
    console.log("uploadExpiry data", data);
    setUploadExpiry(data);
  };

  const setItem = async (item) => {
    console.log(item);

    setVideo(item);

    try {
      const response = await requestVideo();

      setToken(response?.res_data?.result?.token);
    } catch (e) {
      console.log("get token fail", e);
    }

    setScheduledDeletion(
      item.scheduledDeletion ? new Date(item.scheduledDeletion) : new Date()
    );
    setUploadExpiry(
      item.uploadExpiry ? new Date(item.uploadExpiry) : new Date()
    );

    setValue("allowedOrigins.0", item.allowedOrigins);
    setValue("creator", item.creator);
    setValue("requireSignedURLs", item.requireSignedURLs);
    setValue("maxDurationSeconds", item.maxDurationSeconds);
    setValue("thumbnailTimestampPct", item.thumbnailTimestampPct);
    setValue("meta.name", item.meta.name);
  };

  //e: action ==================================================================================

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container" style={{ marginTop: "180px" }}>
      <div className="row">
        <div className="col-lg-8" style={{ height: "500px" }}>
          <div
            style={{
              position: "relative",
              paddingTop: "56.25%", // 퍼센트 값은 문자열로 설정해야 합니다.
            }}
          >
            <iframe
              title={video?.meta?.name}
              src={`https://customer-0zqgjpvvndiasvl7.cloudflarestream.com/${token}/iframe`}
              //   src={`https://customer-0zqgjpvvndiasvl7.cloudflarestream.com/${video?.uid}/iframe`}
              loading="lazy"
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen={true}
            ></iframe>
          </div>
          <h1 className="mt-2">{video?.meta?.name}</h1>
          <span>{String(video.duration).replace(".", ":")}</span>

          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormInput
              {...register("allowedOrigins.0")}
              defaultValue={video?.allowedOrigins}
              placeholder="allowedOrigins"
              label="allowedOrigins"
            />
            <CFormInput
              {...register("creator")}
              defaultValue={video.creator}
              placeholder="creator"
              label="creator"
            />

            <CFormInput
              {...register("maxDurationSeconds")}
              defaultValue={video?.maxDurationSeconds}
              placeholder="maxDurationSeconds"
              label="maxDurationSeconds"
            />

            <CFormCheck
              {...register("requireSignedURLs")}
              className="mt-3 mb-3"
              label="requireSignedURLs"
            />

            <CFormInput
              {...register("thumbnailTimestampPct")}
              defaultValue={video?.thumbnailTimestampPct}
              className="mt-2"
              placeholder="thumbnailTimestampPct"
              label="thumbnailTimestampPct"
            />

            <div className="d-flex">
              <div className="d-flex">
                <DatePicker
                  selected={scheduledDeletion}
                  value={new Date(video?.scheduledDeletion)}
                  key={uuidv4()}
                  onChange={(date) => onChange(date)}
                  placeholder={"scheduledDeletion"}
                />
              </div>

              <div className="d-flex ">
                <DatePicker
                  selected={uploadExpiry}
                  value={new Date(video?.uploadExpiry)}
                  key={uuidv4()}
                  onChange={(date) => onChange1(date)}
                  placeholder={"uploadExpiry"}
                />
              </div>

              <hr />
            </div>

            <CFormInput
              {...register("meta.name")}
              defaultValue={video?.meta?.name}
              placeholder={video?.meta?.name}
              label="vidoe name (meta name)"
            />
            <CButton className="mx-2" type="submit" color="primary">
              저장
            </CButton>
            <CButton
              onClick={() => clickDelete(video.uid)}
              className="my-2m me-2"
              color="secondary"
            >
              삭제
            </CButton>

            <CButton
              onClick={() => navigate("/afterLogin")}
              className="my-2"
              color="secondary"
            >
              뒤로가기
            </CButton>
          </CForm>

          <JSONPretty
            className="mt-5"
            data={videoDetail?.res_data}
            theme={JSONPrettyMon}
          ></JSONPretty>
        </div>

        <div className="col-lg-4">
          <div className="rounded">
            {videoList?.map((item) => (
              <div
                key={item.uid}
                // position-relative
                className="mt-2"
              >
                <div onClick={() => setItem(item)} className="ms-2">
                  <div className="d-flex">
                    <h5>{item.meta.name}</h5>
                    <span className="ms-2">
                      {String(item.duration).replace(".", ":")}
                    </span>
                  </div>

                  {/* <div className="d-flex">
                    <CButton type="submit" className="mx-2">
                      저장
                    </CButton>
                    <CButton
                      onClick={() => clickDelete(item.uid)}
                      className="bg-danger"
                    >
                      삭제
                    </CButton>
                  </div> */}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudStream;
