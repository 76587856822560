import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../../common/axiosConfig";

export const useImageList = () => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(`/api/images`, fetcher);
  return { data, isLoading, error, mutate };
};

export const useImageDetail = (id) => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading, mutate } = useSWR(
    id ? `/api/image/detail?id=${id}` : null,
    fetcher
  );
  return { data, isLoading, error };
};

export const useImageDelete = (id) => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    id ? `/api/image/delete?id=${id}` : null,
    fetcher
  );
  return { trigger, isMutating };
};

export const useImageUpload = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    `/api/image/direct/upload`,
    fetcher
  );
  return { trigger, isMutating };
};

export const useImageUpdate = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/image/update`, fetcher);
  return { trigger, isMutating };
};

export const useImageKey = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/image/sign`, fetcher);
  return { trigger, isMutating };
};
