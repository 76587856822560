import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

//components
import SocialLoginBtn from "./common/components/SocialLoginBtn";

function App() {
  const REST_API_KEY = process.env.REACT_APP_KAKAO_KEY;
  const REDIRECT_URI = process.env.REACT_APP_REDIRECT_KEY;
  const API_KEY = process.env.REACT_APP_GOOGLE_ID;

  const navigate = useNavigate();

  //App 생성시점에서 state 값생성

  const stateToken = uuidv4();

  const KakaoLogin = () => {
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&scope=account_email profile_nickname profile_image talk_message`;
    window.location.href = kakaoURL;
  };

  const NaverLogin = () => {
    const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&state=${stateToken}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT}`;
    window.location.href = naverURL;
  };

  const GoogleLogin = () => {
    const googleURL =
      "https://accounts.google.com/o/oauth2/v2/auth?client_id=" +
      API_KEY +
      "&redirect_uri=" +
      process.env.REACT_APP_GOOGLE_REDIRECT_KEY +
      "&response_type=code" +
      "&scope=email profile";

    window.location.href = googleURL;
  };

  const PlatLogin = () => {
    navigate("/platLogin");
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "300px" }}
    >
      <div className="d-flex flex-column">
        <h1>회원가입하기</h1>
        <h3 className="mt-3 fw-normal">
          소셜 로그인 및 이메일로 가입할 수 있습니다.
          <br />
          {process.env.REACT_APP_API_SERVER}
          <br />
          Test : {process.env.REACT_APP_MY_VALUE}
          <br />
          Test2 : {process.env.REACT_APP_TEST_VALUE}
        </h3>
        <hr className="mt-3" />
        <SocialLoginBtn
          mb={"mb-2"}
          color={"warning"}
          btnName={"카카오"}
          action={KakaoLogin}
        />
        <SocialLoginBtn
          mb={"mb-2"}
          color={"success"}
          btnName={"네이버"}
          action={NaverLogin}
        />
        <SocialLoginBtn
          mb={"mb-2"}
          color={"success"}
          btnName={"구글"}
          action={GoogleLogin}
        />
        <hr className="mt-3" />
        <SocialLoginBtn
          action={PlatLogin}
          color={"light"}
          btnName={"ID / PASSWORD"}
        />

        <hr />
        <div className="d-flex justify-content-end">
          <a href="/join">회원가입</a>
        </div>
      </div>
    </div>
  );
}

export default App;
