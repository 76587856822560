import { useState } from "react";
import RecordRTC from "recordrtc";
import useChatService from "../chat/hook/useChatService";

const useRecorder = () => {
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const timestamp = new Date().getTime();

  const { transcribe, aiChat, checkGoalsList } = useChatService();

  const [stream, setStream] = useState(null);

  const activateRecorder = async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("when audio start: ", stream);
        setStream(stream);
        const options = {
          type: "audio",
          mimeType: "audio/wav",
          disableLogs: true,
          timeSlice: 1000,
          ondataavailable: function (blob) {
            console.log(blob);
            // 이 부분에서 blob 데이터를 서버로 전송할 수 있습니다. 실시간으로 작동하는 함수.
          },
          checkForInactiveTracks: false,
          bitsPerSecond: 128000,
          onTimeStamp: function (timestamp) {
            console.log(timestamp);
          },
          sampleRate: 44100,
          desiredSampRate: 44100,
          bufferSize: 16384,
          numberOfAudioChannels: 2,
        };

        const record = RecordRTC(stream, options);
        setRecorder(record);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const inActivateRecorder = () => {
    if (isRecording) {
      const ok = window.confirm(
        "현재 녹음중입니다 지금까지 녹화한 내용이 삭제됩니다. 정말 비활성화 하시겠습니까?"
      );
      if (ok) {
        stream.stop();
        setStream(null);
        setRecorder(null);
        setIsRecording(false);
        return;
      }
    }

    if (stream) {
      stream.stop();
      setStream(null);
      setRecorder(null);
      setIsRecording(false);
    } else {
      window.alert("녹음 활성화가 되어있지 않습니다.");
    }
  };

  const startRecording = () => {
    if (recorder) {
      setIsRecording(true);
      recorder.startRecording();
    }

    if (!stream) {
      //여기가 문제다.
      window.alert("녹음을 활성화 해주세요. 여긴가..");
    }
  };

  //transcribe api, chat api, check goals api
  const stopRecording = async () => {
    if (recorder) {
      recorder.stopRecording(async () => {
        setIsRecording(false);
        const blob = recorder.getBlob();
        const formData = new FormData();
        formData.append("audio_file", blob, "audio_file.wav");

        transcribe(formData);
        aiChat();
        checkGoalsList();
      });
    }
  };

  const pauseRecording = async () => {
    if (recorder) {
      recorder.pauseRecording();
      window.alert("녹음이 일시중지 되었습니다.");
    }
  };

  const resumeRecording = async () => {
    if (recorder) {
      recorder.resumeRecording();
      window.alert("녹음이 재개되었습니다.");
    }
  };

  return {
    //시작, 중지, 일시정지, 재개 조작함수
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    //동작 유무 상태
    isRecording,
    timestamp,
    //활성화, 비활성화 조작 함수
    activateRecorder,
    inActivateRecorder,
  };
};

export default useRecorder;
