import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../../common/axiosConfig";

export const useTossPay = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/toss/pay`, fetcher);
  return { trigger, isMutating };
};

export const useTossPayConfirm = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/toss/confirm`, fetcher);
  return { trigger, isMutating };
};

export const useTossPayList = (memberId) => {
  const fetcher = (arg) => api.get(arg).then((res) => res.data);
  const { data, error, isLoading } = useSWR(
    memberId ? `/api/toss/pay/list?memberId=${memberId}` : null,
    fetcher
  );
  return { data, isLoading, error };
};

export const useTossPayResponse = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(`/api/toss/search`, fetcher);
  return { trigger, isMutating };
};

export const useTossCancel = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    `/api/toss/pay/cancel`,
    fetcher
  );
  return { trigger, isMutating };
};
