import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const BalloonAnimation = ({ checkList }) => {
  const [showBalloon, setShowBalloon] = useState(false);

  useEffect(() => {
    // 모든 미션이 클리어되었는지 확인
    console.log("check state: ", checkList);
    if (checkList.length > 0) {
      const allMissionsCompleted = checkList.every(
        (mission) => mission.accomplished
      );

      console.log("미션 클리어: ", allMissionsCompleted);
      if (allMissionsCompleted) {
        startAnimation(); // 모든 미션 클리어 시 애니메이션 시작
      }
    }
  }, [checkList]);

  // 미션 클리어 시 풍선이 나오는 애니메이션 시작
  const startAnimation = () => {
    setShowBalloon(true);
    setTimeout(() => {
      setShowBalloon(false);
    }, 2000); // 애니메이션 지속 시간
  };

  return (
    <div>
      {/* 풍선 애니메이션 */}
      <AnimatePresence>
        {showBalloon && (
          <motion.div
            initial={{ opacity: 0, y: 100 }} // 초기 상태 설정
            animate={{ opacity: 1, y: 0 }} // 애니메이션 중간 상태 설정
            exit={{ opacity: 0, y: -100 }} // 애니메이션 종료 상태 설정
            transition={{ duration: 1 }} // 애니메이션 지속 시간
            style={{
              position: "fixed",
              bottom: 0,
              left: "33%",
              transform: "translateX(-50%)",
            }}
          >
            <img
              src="https://www.partypang.co.kr/shopimages/partypang/010002000189.jpg?1611290584"
              alt="balloon"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BalloonAnimation;
