import { useTranscribe, useAiChat, useCheckGoals } from "../api/ChatApi";

import useChatStore from "../store/useChatStore";
//모든 메세지의 배열 상태 관리
//ai id 식별 유무 상태 관리

//useService 라인 쪽에서는 get 방식의 호출은 안된다
//useService get 방식을 호출하면 useHook 사용하는 모든곳에서 get 호출을 진행함. 네트워크 데이터 누수당함.
const useChatService = () => {
  //audio 방식과, 입력방식을 동시에 진행하고자 한다면 해당 내용을 store 에서 관리해야함.
  const { trigger: requestTranscribe, isMutating: isTranscribe } =
    useTranscribe();
  const { trigger: requestAiChat, isMutating: isAiTaking } = useAiChat();
  const { trigger: requestCheckGoals, isMutating: isAiCheking } =
    useCheckGoals();

  //MessageFrom, Balloon 컴포넌트 통신으로 세션 스토어에서 관리.
  //useState 로 관리 될 수 있을까. 각각 컴포넌트에서 함수 실행하면 다른 state 값이므로 안됨
  //emitt 방식으로는 처리 가능함.
  const { setGoalsList, setAiContent, setMessages, messages, aiContent } =
    useChatStore();

  //사용자 오디오 파일 텍스트 변환 api
  const transcribe = async (formData) => {
    try {
      const response = await requestTranscribe(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("transcribe response: ", response);

      if (response && response.status === "ok") {
        setMessages({
          role: response?.text?.messages[0].role,
          content: response?.text?.messages[0].content,
        });
      }
    } catch (error) {
      console.error("transcribe error: ", error);
    }
  };

  //사용자 대화의 배열 데이터를 받아서 ai가 답변을 반환하는 api
  const aiChat = async () => {
    //userChat data form : { role: user, content: "유저가 입력한 챗팅 내용" }
    console.log("ai 한테 들어가는 질문 리스트: ");
    try {
      const response = await requestAiChat({
        messages: [...messages],
      });
      console.log("aiChat response: ", response);

      if (response) {
        setMessages({
          role: response.role,
          content: response.content,
        });
        setAiContent(response.content);
      }
      //ai 답변 식별 상태 업데이트 store 계층으로 다른 컴포넌트 사이 통신.
    } catch (error) {
      console.error("aiChat error: ", error);
    }
  };

  //해당 체크리스트 내용이 체크 되었는지 안되었는지 확인하는 api
  //반환 리스트 타입
  const checkGoalsList = async () => {
    try {
      const response = await requestCheckGoals({
        messages: [...messages],
      });
      //store 계층 저장 함수 다른 컴포넌트사이에 체크 리스트 확인 해야함. 풍선 컴포넌트, Chat 컴포넌트
      console.log("check goals list response: ", response);
      setGoalsList(response.goal_list);
    } catch (error) {
      console.error("checkGoalsList error: ", error);
    }
  };

  // console.log("check messages update: ", messages);

  return {
    transcribe,
    aiChat,
    checkGoalsList,
    //setMessage 상태 함수 그 외에는 편하게 사용가능
    setMessages,
    messages,
    aiContent,
    isAiTaking,
    isTranscribe,
    isAiCheking,
  };
};

export default useChatService;
