export const validateImageFile = ({ imageFile }) => {
  const file = imageFile[0];

  if (!file.type.includes("image/jpeg") && !file.type.includes("image/png")) {
    window.alert("jpg 또는 png 형식의 이미지 파일만 업로드할 수 있습니다.");
    return false;
  }

  if (file.size > 500 * 1024) {
    window.alert("파일 크기는 최대 500KB를 초과할 수 없습니다.");
    return false;
  }

  const img = new Image();
  const _URL = window.URL || window.webkitURL;
  img.src = _URL.createObjectURL(file);

  img.onload = () => {
    if (img.width < 500) {
      window.alert("가로 크기가 500px 미만입니다.");
      return false;
    }
  };
  return true;
};

// get the key data from the server
const KEY = "yLSyRaVJB49sgVxn3sLI4OsI78gnnFQP";
const EXPIRATION = 60 * 60 * 24; // 1 day

export const bufferToHex = (buffer) =>
  [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");

export const generateSignedUrl = async (url) => {
  const encoder = new TextEncoder();
  const secretKeyData = encoder.encode(KEY);
  const key = await crypto.subtle.importKey(
    "raw",
    secretKeyData,
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  );

  // Attach the expiration value to the `url`
  const expiry = Math.floor(Date.now() / 1000) + EXPIRATION;
  url.searchParams.set("exp", expiry);
  // `url` now looks like
  // https://imagedelivery.net/cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

  const stringToSign = url.pathname + "?" + url.searchParams.toString();
  // for example, /cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

  // Generate the signature
  const mac = await crypto.subtle.sign(
    "HMAC",
    key,
    encoder.encode(stringToSign)
  );
  const sig = bufferToHex(new Uint8Array(mac).buffer);

  // And attach it to the `url`
  url.searchParams.set("sig", sig);

  return new Response(url);
};
