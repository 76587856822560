import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useChatStore = create(
  persist(
    (set, get) => ({
      goalsList: [],
      setGoalsList: (_checkGoalsList) =>
        set((state) => ({
          checkGoalsList: _checkGoalsList,
        })),

      //useState 안하고 store 정리한 이유는 지나친  컴포넌트 사이 Prop Drilling 막기 위함.
      aiContent: "",
      setAiContent: (_aiContent) => set((state) => ({ aiContent: _aiContent })),

      //모든 채팅방이 하나의 messages 상태에 의존. 만약 각개의 대화 history를 가지려면 useState으로 컴포넌트 독립적으로 상태 유지해야함.
      messages: [],
      setMessages: (_messages) =>
        set((state) => ({ messages: [...state.messages, _messages] })),

      clearMessages: () => set((state) => ({ messages: [] })),

      naviState: {},
      setNaviState: (_naviState) => set((state) => ({ naviState: _naviState })),
    }),

    {
      name: "session-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useChatStore;
