import React, { useState } from "react";

//lib
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

//ui components
import { CButton, CForm, CFormCheck, CFormTextarea } from "@coreui/react";

//server
import { useKakaoFriendsTalk } from "../api/MessageApi";
import { useImages } from "./api/TalkImageApi";

//session memory
import useSessionStore from "../store/useSessionStore";

const TalkImage = () => {
  const { data } = useImages();
  const navigate = useNavigate();

  const [imageId, setImageId] = useState(null);
  const [imgUrl, setImgUrl] = useState("");

  const { userInfo } = useSessionStore();

  const { trigger: sendTalkMsg } = useKakaoFriendsTalk();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);

    if (imgUrl === "") {
      window.alert("이미지를 선택해주세요");
      return;
    }

    if (data["message"] === "") {
      window.alert("메세지 내용을 입력해주세요");
      return;
    }

    const sendData = {
      img_url: imgUrl,
      email: userInfo.email,
      loginType: userInfo.loginType,
      message: data["message"],
    };
    try {
      const response = await sendTalkMsg(sendData);
      console.log("친구톡 발송 응답", response);
    } catch (e) {
      console.log(e);
      window.alert(e.response.data.res_msg);
    }
    console.log(sendData);
  };

  const clickImage = (url, id) => {
    setImageId(id);
    setImgUrl(url);

    if (imageId === id) {
      //중복클릭은 해제
      setImageId(null);
      setImgUrl("");
    }
    console.log(url);
  };

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column w-25 h-50">
          {data?.res_data?.map((item) => (
            <div className="d-flex flex-column" key={item.id}>
              <img
                onClick={() => clickImage(item.img_url, item.id)}
                className="mt-5"
                alt="logo"
                src={item.img_url}
              />
              <div>이미지 명: {item.img_name}</div>
              <div>이미지 타입: {item.img_type}</div>
              <div>
                <CFormCheck
                  onChange={() => clickImage(item.img_url, item.id)}
                  checked={imageId === item.id}
                  label="이미지 체크박스"
                />
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-center">
            <CButton onClick={() => navigate("/afterLogin")}>뒤로가기</CButton>
          </div>
        </div>
        <div className="d-flex flex-column mx-5 mt-5">
          <div>
            <CFormTextarea
              {...register("message")}
              label="톡 메세지"
              rows={5}
              placeholder="톡 메세지 내용을 입력해주세요"
            ></CFormTextarea>
          </div>
          <div className="mt-2">
            <CButton type="submit">친구톡 보내기</CButton>
          </div>
        </div>
      </div>
    </CForm>
  );
};

export default TalkImage;
