import React from "react";
import { CButton } from "@coreui/react";

const SocialLoginBtn = ({ btnName, mb, color, action }) => {
  return (
    <CButton onClick={action} className="mb-2" color={color}>
      <span className="fw-medium">{btnName}</span>
    </CButton>
  );
};

export default SocialLoginBtn;
