import React from "react";
import { CForm, CFormInput, CButton } from "@coreui/react";

import { useNavigate } from "react-router-dom";
//lib
import { useForm } from "react-hook-form";
import useSessionStore from "../store/useSessionStore";

//server
import { useVertifyLogin } from "../api/AfterLoginApi";

const VertifyPage = () => {
  const navigate = useNavigate();
  const { trigger: vertify } = useVertifyLogin();
  const { userInfo, updateUserInfo } = useSessionStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    data["email"] = userInfo.email;
    data["loginType"] = userInfo.loginType;

    console.log("send verify data = >", data);

    const response = await vertify(data);
    console.log("respon from server = ", response);

    if (response.res_code === "400") {
      setError("root.serverError", {
        type: response.res_code,
        message: response.res_msg,
      });
    }

    if (response.res_code === "200") {
      updateUserInfo(response.res_data);
      navigate("/afterLogin");
    }
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "300px" }}
    >
      <div className="d-flex flex-column justify-content-center">
        <h1>인증번호 입력해주세요.</h1>
        <h3>인증 메일 : {userInfo.email}</h3>
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <CFormInput
            className="d-flex justify-content-center mt-2"
            {...register("authNumber")}
            placeholder="인증번호 6자리를 입력해주세요."
          />
          {errors?.root && <span>{errors.root.serverError.message}</span>}
          <CButton className="mt-3 w-100" type="submit">
            인증하기
          </CButton>
        </CForm>
      </div>
    </div>
  );
};

export default VertifyPage;
