import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import api from "../../common/axiosConfig";

export const useMessage = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/kakao/alarm", fetcher);
  return { trigger, isMutating };
};

export const useEmail = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation("/api/gmail/send", fetcher);
  return { trigger, isMutating };
};

export const useKakaoFriendsTalk = () => {
  const fetcher = (url, { arg }) => api.post(url, arg).then((res) => res.data);
  const { trigger, isMutating } = useSWRMutation(
    "/api/plat/friends/talk",
    fetcher
  );
  return { trigger, isMutating };
};
