import React from "react";

import { useNavigate } from "react-router-dom";
import {
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CForm,
} from "@coreui/react";

//lib
import { useForm } from "react-hook-form";
import useSessionStore from "../store/useSessionStore";

//server
import { usePlatLogin } from "../api/AfterLoginApi";

const PlatLogin = () => {
  const navigate = useNavigate();

  const { updateUserInfo } = useSessionStore();
  const { trigger: login } = usePlatLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const { res_data } = await login(data);
      console.log("onSubmit 에서 체킹 리스폰", res_data);

      updateUserInfo(res_data);

      res_data.jwtToken === "" ? navigate("/vertify") : navigate("/afterLogin");
    } catch ({ response: { data } }) {
      setError("root.serverError", {
        type: data.res_code,
        message: data.res_msg,
      });
    }
  };

  const onError = (error) => {
    console.log(error);
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "300px" }}
    >
      <CForm onSubmit={handleSubmit(onSubmit, onError)}>
        <h1>로그인</h1>
        <hr />

        <CInputGroup className="mb-3">
          <CInputGroupText>이메일</CInputGroupText>
          <CFormInput
            type="email"
            {...register("email")}
            placeholder="이메일을 입력해주세요."
          />
        </CInputGroup>
        <CInputGroup className="mb-3">
          <CInputGroupText>비밀번호</CInputGroupText>
          <CFormInput
            {...register("password")}
            type="password"
            placeholder="비밀번호를 입력해주세요."
          />
        </CInputGroup>
        {errors?.root && <span>{errors.root.serverError.message}</span>}
        <div className="d-flex justify-content-center">
          <CButton type="submit">로그인</CButton>
        </div>
      </CForm>
    </div>
  );
};

export default PlatLogin;
