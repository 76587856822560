import axios from "axios";

const pythonApi = axios.create({
  baseURL: process.env.REACT_APP_PYTHON_SERVER,
});

// Request interceptor
pythonApi.interceptors.request.use(
  (config) => {
    //python global config here...
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
pythonApi.interceptors.response.use(
  (response) => {
    // console.log("python api response here");
    return response;
  },
  (error) => {
    console.error("python api error: ", error);

    return Promise.reject(error);
  }
);

export default pythonApi;
