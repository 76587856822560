import React, { useEffect, useRef, useState } from "react";

import { CForm, CButton, CFormCheck, CFormSelect } from "@coreui/react";

//toss_sdk
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";

import useSessionStore from "../store/useSessionStore";

//server
import { useTossPay } from "./api/TossApi";

// 구매자의 고유 아이디를 불러와서 customerKey로 설정하세요.
// 이메일・전화번호와 같이 유추가 가능한 값은 안전하지 않습니다.
const widgetClientKey = "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";
const customerKey = "cPGO9ne8pGKSvdTDLlWdf";
// const paymentWidget = PaymentWidget(widgetClientKey, PaymentWidget.ANONYMOUS) // 비회원 결제d

const Toss = () => {
  const { userInfo } = useSessionStore();

  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentMethodsWidgetRef = useRef(null);
  const [price, setPrice] = useState(50_000);

  const { trigger: requestTossPay } = useTossPay();

  useEffect(() => {
    const fetchPaymentWidget = async () => {
      try {
        const loadedWidget = await loadPaymentWidget(
          widgetClientKey,
          customerKey // 비회원 결재일 시 PaymentWidget.ANONYMOUS 값으로 변경
        );
        setPaymentWidget(loadedWidget);
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    };

    fetchPaymentWidget();
  }, []);

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      "#payment-widget",
      { value: price },
      { variantKey: "DEFAULT" }
    );

    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }, [paymentWidget, price]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }
    //사용자가 선택한 물건의 값을 세션에 혹은 localstorage에 저장했다가 계산 요청이 들어올때 밑에 함수로 계산금액을 업데이트하면 됨.
    //현재는 예제라 react state로 관리하고 있음.
    paymentMethodsWidget.updateAmount(price);
  }, [price]);

  const handlePaymentRequest = async () => {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    const orderId = nanoid();

    try {
      const response = await requestTossPay({
        orderId,
        amount: price,
        memberId: userInfo?.memberId,
      });
      console.log("requestTossPay response: ", response.res_data);
    } catch (error) {
      console.error("requestTossPay error: ", error);
    }

    try {
      await paymentWidget?.requestPayment({
        orderId,
        orderName: "토스 티셔츠 외 2건",
        customerName: userInfo?.username,
        customerEmail: userInfo?.email,
        customerMobilePhone: "01012341234",
        successUrl: `${window.location.origin}/pay/success`,
        failUrl: `${window.location.origin}/pay/fail`,
      });
    } catch (error) {
      //결재실패시 failUrl Redirect
      console.error("Error requesting payment:", error);
    }
  };

  return (
    <div>
      {/* 할인 쿠폰 */}
      <label htmlFor="coupon-box">
        <input
          id="coupon-box"
          type="checkbox"
          onChange={(event) => {
            setPrice(event.target.checked ? price - 5_000 : price + 5_000);
          }}
        />
        <span>5,000원 쿠폰 적용</span>
      </label>
      {/* 결제 UI, 이용약관 UI 영역 */}
      <div id="payment-widget" />
      <div id="agreement" />
      {/* 결제하기 버튼 */}
      <div className="d-felx justify-content-center">
        <CButton onClick={handlePaymentRequest}>결제하기</CButton>
      </div>
    </div>
  );
};

export default Toss;
