import React from "react";
import { CForm, CFormInput, CButton } from "@coreui/react";
import axios from "axios";

//session
import useSessionStore from "../store/useSessionStore";

//lib
import { useForm } from "react-hook-form";

const AuthNumberPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { tmpToken } = useSessionStore();

  const onSubmit = async (data) => {
    console.log("submit data => ", data);

    const response = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/api/certificate-mail`,
      data,
      {
        headers: {
          Authorization: `Bearer ${tmpToken}`,
        },
      }
    );

    console.log("이메일 인증 후 서버에서 토큰 발급 받기 = >", response.data);
  };

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "300px" }}
    >
      <div className="d-flex flex-column">
        <h1>이메일 인증번호를 입력해주세요</h1>
        <h3 className="mt-3 fw-normal">
          이메일을 통해 받은 인증번호 6자리를 입력해주세요.
        </h3>
        <hr />
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex">
            <CFormInput
              {...register("auth_number", {
                maxLength: {
                  value: 6,
                  message: "인증코드는 6자리입니다.",
                },
                pattern: {
                  value: /^[0-9]+$/, // 숫자만 허용하는 정규 표현식,
                  message: "숫자 데이터만 허락합니다.",
                },
              })}
              className="me-3 w-75"
              type="text"
            />

            <CButton className="w-25" color="primary">
              <span className="fw-medium">인증받기</span>
            </CButton>
          </div>
          {errors && <div>{errors.auth_number?.message}</div>}
        </CForm>
      </div>
    </div>
  );
};

export default AuthNumberPage;
