import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import useSessionStore from "../store/useSessionStore";

import { useKakaoLogin } from "./api/LoginApi";

const KakaoLogin = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const KakaoCode = queryParams.get("code");

  const { updateUserInfo } = useSessionStore();
  const { trigger: kakaoLogin } = useKakaoLogin(KakaoCode);

  const requestKakaoLogin = async () => {
    try {
      const { res_data } = await kakaoLogin();

      updateUserInfo(res_data);

      res_data.jwtToken === "" ? navigate("/vertify") : navigate("/afterLogin");
    } catch (e) {
      console.log(e);
      navigate("/");
    }
  };

  useEffect(() => {
    requestKakaoLogin();
  }, []);
};

export default KakaoLogin;
