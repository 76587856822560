import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useTossPayConfirm } from "./api/TossApi";
import useSessionStore from "../store/useSessionStore";

const PaySuccess = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount");
  const paymentType = searchParams.get("paymentType");
  const paymentKey = searchParams.get("paymentKey");
  const orderId = searchParams.get("orderId");

  const { userInfo } = useSessionStore();

  const [data, setData] = useState({});

  const { trigger: confirmRequestPay } = useTossPayConfirm();

  const confirm = async () => {
    try {
      const response = await confirmRequestPay({
        orderId,
        paymentKey,
        amount,
        //memberId 는 임시로 고정으로 설정함 실제로는 회원 고유 Id 동적으로 넣어줘야함.
        memberId: userInfo?.memberId,
      });

      console.log("confirm response: ", response?.res_data);
      if (response?.res_data.code === "ALREADY_PROCESSED_PAYMENT") {
        window.alert("결재가 완료되었습니다.");
        navigate("/afterLogin");
      }
      setData(response?.res_data);
    } catch (error) {
      console.error("confirn Spring Api Error: ", error);
      //결제 실패창으로 이동. (toss 모듈도 만약 에러 발생 시 해당 페이지로 이동함.)
      navigate("/pay/fail", {
        state: {
          response: error.response.data,
        },
      });
    }
  };

  useEffect(() => {
    confirm();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="d-flex justify-content-center mt-5"
      style={{ marginTop: "200px" }}
    >
      <div className="d-flex flex-column">
        <h1>주문완료</h1>
        <h6>주문번호: {data.orderId}</h6>
        <h6>축하합니다. 🎉 이제 새로운 지식을 습득해보세요.</h6>

        <div className="d-flex mt-5">
          <div>
            <img
              alt="logo"
              src="https://cdn.inflearn.com/public/files/blogs/1a0fbdae-5d2d-4ef4-a5fc-49a2980d27cf/spring.png"
              width={200}
            ></img>
          </div>

          <div className="mt-3 mx-2 d-flex flex-column">
            <h4>{data.orderName}</h4>
            <h4>{data.totalAmount}</h4>
            <h4>결제상태: {data.status}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaySuccess;
