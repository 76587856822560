import { useEffect } from "react";

import useSessionStore from "../store/useSessionStore";
import { useNavigate } from "react-router-dom";
import { useNaverLogin } from "./api/LoginApi";

const NaverLogin = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const naverCode = queryParams.get("code");
  const stateToken = queryParams.get("state");

  const navigate = useNavigate();
  const { updateUserInfo } = useSessionStore();
  const { trigger: naverLogin } = useNaverLogin(naverCode, stateToken);

  const requestNaverLogin = async () => {
    try {
      const { res_data } = await naverLogin();

      updateUserInfo(res_data);
      res_data.jwtToken === "" ? navigate("/vertify") : navigate("/afterLogin");
    } catch (e) {
      console.log(e);
      navigate("/");
    }
  };

  useEffect(() => {
    requestNaverLogin();
  }, []);
};

export default NaverLogin;
